import {SignalRContext} from "../SignalRContext";
import React, {useContext, useEffect, useState} from "react";
import ChatTab from "./ChatTab";
import {FiChevronDown, FiMessageSquare, FiSend, FiUser, FiUsers} from "react-icons/fi";
import Card, {CardPadding, CardStyle} from "./Card";
import SlideOver from "./SlideOver";
import Button, {ButtonType} from "./Button";
import {useAppDispatch, useAppSelector} from "../state/hooks";
import {selectCommunicationChatGroups, updateFullChatMessages, updateRecentChats} from "../state/communication";
import {selectUser} from "../state/user";

function ChatOverlay() {
  const dispatch = useAppDispatch();
  const signalR = useContext(SignalRContext);
  const [showFriends, setShowFriends] = useState<boolean | undefined>(undefined);
  const [currentOpenChatId, setCurrentOpenChatId] = useState<string | undefined>(undefined);
  const [messageToSend, setMessageToSend] = useState<string>();
  const selfUser = useAppSelector(selectUser);
  const chatGroups = useAppSelector(selectCommunicationChatGroups);
  
  useEffect(() => {
    signalR.invoke('GetRecentChats', null)
      ?.then((data) => {
        dispatch(updateRecentChats(data));
      });
  }, []);
  
  const onClickCreateDirectMessageChat = async (userId: string) => {
    await signalR.invoke('CreateDirectMessageChat', userId)
  }
  
  const onClickSendMessage = async (groupId: string, message?: string) => {
    await signalR.invoke('SendMessageToChat', groupId, message)
    setMessageToSend('');
  }
  
  const toggleFocusedChat = async (groupId: string) => {
    if (groupId !== currentOpenChatId) {
      setCurrentOpenChatId(groupId);
      const messages = await signalR.invoke('GetChatMessages', groupId);
      dispatch(updateFullChatMessages({
        groupId,
        messages,
      }));
    } else {
      setCurrentOpenChatId(undefined);
    }
  }
  
  return (
    <div>
      <div className="absolute top-0 left-0 w-screen h-screen pointer-events-none">
        <div className="absolute bottom-0 right-0 pointer-events-auto">
          <div className="flex gap-1 mr-2">
            {chatGroups.map(x => (
              <div className="relative">
                {currentOpenChatId && currentOpenChatId === x.groupId &&
                    <div
                        className="absolute bottom-full w-[20rem] h-[25rem] overflow-hidden right-0 mb-1 bg-[#1f1f1f] text-[#cfcdbf] section rounded-t rounded-bl text-xs z-10">
                        <div className="flex flex-col h-full">
                            <div
                                onClick={() => setCurrentOpenChatId(undefined)}
                                className="flex justify-between items-center border-b-[1px] border-black border-opacity-50 p-2 cursor-pointer">
                                <span>{x.name}</span>
                                <FiChevronDown/>
                            </div>
                            <div className="flex flex-col-reverse max-h-[20rem] overflow-y-auto remove-scroll-bar p-2 border-t border-t-[#B67E4F]/15 border-b border-b-black/50">
                                <div className="flex flex-col gap-3">
                                  {x.messages !== undefined && x.messages.map(message => (
                                    <div
                                      className={message.userName === selfUser?.name ? 'bg-[#B67E4F] p-1 rounded-sm rounded-br-none w-[75%] ml-auto shadow' : 'text-[#1f1f1f] bg-[#cfcdbf] p-1 rounded-sm rounded-bl-none w-[75%] mr-auto shadow'}>
                                      {message.userName}: {message.body}
                                    </div>
                                  ))}
                                </div>
                            </div>
                            <div className="flex gap-1 mt-auto border-t border-t-[#B67E4F]/15 p-1">
                                <div className="rounded border border-black/50 w-full max-h-24 h-full">
                                    <textarea
                                        value={messageToSend}
                                        onChange={event => setMessageToSend(event.target.value)}
                                        className="w-full max-h-24 h-full bg-transparent border rounded border-[#B67E4F]/15 p-2 focus:outline-none text-wrap"/>
                                </div>
                                <Button className="p-2 self-end" onClick={() => onClickSendMessage(x.groupId, messageToSend)} type={ButtonType.DEAFULT}>
                                    <FiSend className="text-[#cfcdbf]"/>
                                </Button>
                            </div>
                        </div>
                    </div>
                }
                <ChatTab onToggle={() => toggleFocusedChat(x.groupId)} hasMessage={false}>{x.name}</ChatTab>
              </div>
            ))}
            {/*<ChatTab onToggle={() => {*/}
            {/*}} hasMessage={false} isOpen={false}>Trade</ChatTab>*/}
            {/*<ChatTab onToggle={() => {*/}
            {/*}} hasMessage={false} isOpen={false}>*/}
            {/*  <FiGlobe className="m-auto"/>*/}
            {/*</ChatTab>*/}
            <button
              onClick={() => setShowFriends(true)}
              className="bg-[#cfcdbf] ease-in-out duration-150 hover:bg-[#cfcdbf]/80 text-[#1f1f1f] rounded-t py-px px-2 text-sm cursor-pointer">
              <FiUsers/>
            </button>
          </div>
        </div>
      </div>
      {showFriends &&
          <SlideOver onClose={() => setShowFriends(undefined)}>
              <Card className="flex justify-between items-center p-3" type={CardStyle.DEFAULT}
                    padding={CardPadding.NONE}>
                      <span className="text-sm">
                          Your Friends
                      </span>
              </Card>
              <Card className="flex justify-between items-center" type={CardStyle.DEFAULT} padding={CardPadding.NONE}>
                      <span className="text-sm pl-3">
                          TomDb
                      </span>
                  <ul className="flex section_list_row divide-x divide-[#171716]">
                      <li
                          onClick={() => onClickCreateDirectMessageChat('usr_BNorjC_koM0nAMRWOeKtT')}
                          className="relative group w-full flex justify-center items-center overflow-hidden"
                      >
                          <FiMessageSquare
                              className=" transform translate-y-0 group-hover:translate-y-full ease-in-out duration-300 h-4 w-4"/>
                          <span
                              className="absolute text-xs scale-0 group-hover:scale-100 ease-in-out duration-300 text-[#0C0C0CCC]">Chat</span>
                      </li>
                      <li className="relative group w-full flex justify-center items-center overflow-hidden">
                          <FiUser
                              className=" transform translate-y-0 group-hover:translate-y-full ease-in-out duration-300 h-4 w-4"/>
                          <span
                              className="absolute text-xs scale-0 group-hover:scale-100 ease-in-out duration-300 text-[#0C0C0CCC]">Info</span>
                      </li>
                  </ul>
              </Card>
          </SlideOver>
      }
    </div>
  );
}

export default ChatOverlay;
