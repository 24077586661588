import {withAuth} from "oidc-react";
import React, {useState} from "react";
import SlideOver from "../components/SlideOver";
import Card, {CardPadding, CardStyle} from "../components/Card";
import VitalBar from "../components/VitalBar";
import {FiCrosshair, FiDollarSign, FiMail, FiMessageSquare, FiThumbsDown, FiThumbsUp} from "react-icons/fi";
import {IoIosSwap} from "react-icons/io";

function JailPage() {
  const [showUserDetails, setUserDetails] = useState<string | undefined>(undefined);
  return (
    <div>
      <div className="flex flex-col p-3 section">
        <table className="table-auto text-left text-[#cfcdbf]">
          <thead>
          <tr className="text-sm font-extralight">
            <th>User</th>
            <th>Reason</th>
            <th>Time</th>
          </tr>
          </thead>
          <tbody className="divide-y divide-[#171716] text-xs">
          <tr>
            <td className="flex items-center gap-1">
              <img className="rounded h-6 w-6" src="https://imgur.com/7bLroYs.png" alt="profile"/>
              <span>JKE</span>
            </td>
            <td>Was playing with a match and a can of flammable spray</td>
            <td>44 mins</td>
            <td className="text-[#BE8250FF] ease-in-out duration-150 hover:brightness-150 cursor-pointer"
                onClick={() => setUserDetails('jake')}>View
            </td>
          </tr>
          <tr>
            <td className="flex items-center gap-1">
              <img className="rounded h-6 w-6" src="https://imgur.com/7bLroYs.png" alt="profile"/>
              <span>TomDB</span>
            </td>
            <td>Was in the next room to JKE when he was playing with a match..</td>
            <td>12 mins</td>
            <td className="text-[#BE8250FF] ease-in-out duration-150 hover:brightness-150 cursor-pointer">View</td>
          </tr>
          </tbody>
        </table>
      </div>
      {showUserDetails &&
          <SlideOver onClose={() => setUserDetails(undefined)}>
              <Card type={CardStyle.DEFAULT} padding={CardPadding.NONE}>
                  <ul className="flex section_list_row divide-x divide-[#171716]">
                      <li className="w-full flex justify-center">
                          Stats
                      </li>
                      <li className="w-full flex justify-center">
                          Report
                      </li>
                  </ul>
              </Card>
              <Card type={CardStyle.ORANGE} padding={CardPadding.SMALL}>
                  <div className="flex flex-col gap-1">
                      <div className="w-full max-h-24 bg-white relative">
                          <img className="h-full w-full object-cover object-bottom border border-[#ecb274]"
                               src="https://imgur.com/Pv92uqm.png" alt="profile banner"/>
                      </div>
                      <div className="flex max-h-16 max-w-16 gap-1 items-center">
                          <img className="h-full  w-full border border-[#ecb274] object-cover"
                               src="https://imgur.com/7bLroYs.png"
                               alt="profile"/>
                          <div className="flex flex-col text-xs whitespace-nowrap px-1">
                              <span><strong>Name:&nbsp;</strong>JKE</span>
                              <span><strong>Level:&nbsp;</strong>1</span>
                              <span><strong>Last Action:&nbsp;</strong>Now</span>
                              <span><strong>State:&nbsp;</strong>In Hospital</span>
                          </div>
                      </div>
                  </div>
              </Card>
              <Card>
                  <VitalBar minValue={30} maxValue={100} color="#932d2e" title="Health"/>
              </Card>
              <div className="grid grid-cols-2 gap-3 text-sm text-[#cfcdbf]">
                  <Card className="flex justify-center gap-1" padding={CardPadding.SMALL}>
                      <span>LEVEL</span>
                      <span className="text-[#BE8250FF]">1</span>
                  </Card>
                  <Card className="flex justify-center gap-1" padding={CardPadding.SMALL}>
                      <span>AGE</span>
                      <span className="text-[#BE8250FF]">10</span>
                  </Card>
              </div>
              <Card type={CardStyle.DEFAULT} padding={CardPadding.NONE}>
                  <ul className="grid grid-cols-7 section_list_row divide-x divide-[#171716]">
                      <li className="relative group w-full flex justify-center items-center overflow-hidden">
                          <FiCrosshair
                              className=" transform translate-y-0 group-hover:translate-y-full ease-in-out duration-300 h-4 w-4"/>
                          <span
                              className="absolute text-xs scale-0 group-hover:scale-100 ease-in-out duration-300 text-[#0C0C0CCC]">Attack</span>
                      </li>
                      <li className="relative group w-full flex justify-center items-center overflow-hidden">
                          <FiMail
                              className=" transform translate-y-0 group-hover:translate-y-full ease-in-out duration-300 h-4 w-4"/>
                          <span
                              className="absolute text-xs scale-0 group-hover:scale-100 ease-in-out duration-300 text-[#0C0C0CCC]">Mail</span>
                      </li>
                      <li className="relative group w-full flex justify-center items-center overflow-hidden">
                          <FiMessageSquare
                              className=" transform translate-y-0 group-hover:translate-y-full ease-in-out duration-300 h-4 w-4"/>
                          <span
                              className="absolute text-xs scale-0 group-hover:scale-100 ease-in-out duration-300 text-[#0C0C0CCC]">Chat</span>
                      </li>
                      <li className="relative group w-full flex justify-center items-center overflow-hidden">
                          <FiDollarSign
                              className=" transform translate-y-0 group-hover:translate-y-full ease-in-out duration-300 h-4 w-4"/>
                          <span
                              className="absolute text-xs scale-0 group-hover:scale-100 ease-in-out duration-300 text-[#0C0C0CCC]">Send</span>
                      </li>
                      <li className="relative group w-full flex justify-center items-center overflow-hidden">
                          <IoIosSwap
                              className=" transform translate-y-0 group-hover:translate-y-full ease-in-out duration-300 h-4 w-4"/>
                          <span
                              className="absolute text-xs scale-0 group-hover:scale-100 ease-in-out duration-300 text-[#0C0C0CCC]">Trade</span>
                      </li>
                      <li className="relative group w-full flex justify-center items-center overflow-hidden">
                          <FiThumbsUp
                              className=" transform translate-y-0 group-hover:translate-y-full ease-in-out duration-300 h-4 w-4"/>
                          <span
                              className="absolute text-xs scale-0 group-hover:scale-100 ease-in-out duration-300 text-[#0C0C0CCC]">Add</span>
                      </li>
                      <li className="relative group w-full flex justify-center items-center overflow-hidden">
                          <FiThumbsDown
                              className=" transform translate-y-0 group-hover:translate-y-full ease-in-out duration-300 h-4 w-4"/>
                          <span
                              className="absolute text-xs scale-0 group-hover:scale-100 ease-in-out duration-300 text-[#0C0C0CCC]">Block</span>
                      </li>
                  </ul>
              </Card>
              <Card>

              </Card>
          </SlideOver>
      }
    </div>

  );
}

export default withAuth(JailPage);