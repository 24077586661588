import './skill-tree-page.css';
import SkillTreeUnlock from "../components/SkillTreeUnlock";

const SkillTreePage = () => {
  const lineStyle = {
    stroke: 'red',
    strokeWidth: '2'
  };
  return (
    <div className="section h-full w-full flex flex-col">
      <div className="flex flex-col text-center p-4">
        <h3 className="text-md text-[#cfcdbf]">Skill Tree</h3>
        <h4 className="text-xs text-[#cfcdbf]">0 Skill Points Available</h4>
      </div>
      <svg className="relative h-full" xmlns="http://www.w3.org/2000/svg">
        {/* LINES MUST BE DRAWN FIRST */}
        <line
          x1={200 + 20}
          y1={200 + 20}
          x2={300 + 20}
          y2={300 + 20}
          style={lineStyle}
        />
        <SkillTreeUnlock top={200} left={200} currentTier={2} maxTier={5} title="Fast Learner"
                         description="Decrease education course duration by 1%"/>
        <SkillTreeUnlock top={300} left={300} currentTier={2} maxTier={5} title="Fast Learner"
                         description="Decrease education course duration by 1%"/>
      </svg>
    </div>
  );
}

export default SkillTreePage;