import Card, {CardPadding, CardStyle} from "../components/Card";
import React, {useContext} from "react";
import {SignalRContext} from "../SignalRContext";
import Button, {ButtonType} from "../components/Button";

const TokensPage = () => {
  const signalR = useContext(SignalRContext);
  const replenishCourage = async () => {
    await signalR.invoke('ReplenishCourage');
  };
  const replenishStamina = async () => {
    await signalR.invoke('ReplenishStamina');
  };
  return (
    <div className="flex flex-col gap-3">
      <Card padding={CardPadding.NONE} className="grid grid-cols-12 gap-3 items-center">
        <Card className="flex flex-col h-full gap-3 col-span-2">
          <img className="border border-[#ecb274] object-cover rounded "
               src="https://imgur.com/S040d62.png"
               alt="profile"/>
          <span className="mx-auto">Nova</span>
        </Card>
        <p className="mx-2 text-sm font-extralight col-span-10">Tidal Tokens, the coveted currency of Tidal City, hold the power to
          replenish your courage, and stamina bars when the rigors of city life take their toll. Whether you're
          embarking on daring heists, engaging
          in intense confrontations, or simply navigating the murky depths of the city's underworld, Tidal Tokens serve
          as your lifeline, offering a vital boost to keep you resilient and ready for whatever challenges lie ahead.
          Invest wisely, for in Tidal City, survival often depends on maintaining your strength and resilience in the
          face of relentless adversity.</p>
      </Card>
      <div className="grid xl:grid-cols-3 gap-3">
        <Card type={CardStyle.ORANGE} className="flex justify-between items-center">
          <div className="flex flex-col">
            <span className="text-[#1f1f1f]">Refill Courage</span>
            <span className="text-[#1f1f1f] text-xs font-light">10 T-Tokens</span>
          </div>
          <div className="mt-auto">
            <Button type={ButtonType.DARK} onClick={() => replenishCourage()}>
              <span className="text-sm">Purchase</span>
            </Button>
          </div>
        </Card>
        <Card type={CardStyle.ORANGE} className="flex justify-between items-center">
          <div className="flex flex-col">
            <span className="text-[#1f1f1f]">Refill Stamina</span>
            <span className="text-[#1f1f1f] text-xs font-light">10 T-Tokens</span>
          </div>
          <div className="mt-auto">
            <Button type={ButtonType.DARK} onClick={() => replenishStamina()}>
              <span className="text-sm">Purchase</span>
            </Button>
          </div>
        </Card>
        <Card type={CardStyle.ORANGE} className="flex justify-between items-center">
          <div className="flex flex-col">
            <span className="text-[#1f1f1f]">Need More T-Tokens?</span>
            <span className="text-[#1f1f1f] text-xs font-light">Visit The Barn To Buy More</span>
          </div>
          <div className="mt-auto">
            <Button type={ButtonType.DARK} onClick={() => {
            }}>
              <span className="text-sm">The Barn</span>
            </Button>
          </div>
        </Card>
      </div>
    </div>
  );
}

export default TokensPage;