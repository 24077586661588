interface ChatTabProps {
    hasMessage: boolean;
    onToggle: () => void;
}

function ChatTab({ children, hasMessage, onToggle }: React.PropsWithChildren<ChatTabProps>) {
    let bg = "bg-[#cfcdbf] ease-in-out duration-150 hover:bg-[#cfcdbf]/80";
    if (hasMessage) {
        bg = "bg-[#BE8250] ease-in-out duration-150 hover:bg-[#BE8250]/80"
    }
    return (
      <div className="flex relative" onClick={onToggle}>
        <div className={`flex text-[#1f1f1f] rounded-t py-px px-2 text-sm cursor-pointer ${bg}`}>
            {children}
        </div>
      </div>
    );
}

export default ChatTab;
