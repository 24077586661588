import React from 'react';

interface userProfileProps {
  onClose: () => void;
  width?: SlideOverWidth;
}

export enum SlideOverWidth {
  XSMALL = "max-w-xs",
  SMALL = "max-w-sm",
  DEFAULT = "max-w-md",
  LARGE = "max-w-lg",
  XLARGE = "max-w-xl"
}

function SlideOver({width = SlideOverWidth.SMALL, onClose, children}: React.PropsWithChildren<userProfileProps>) {
  return (
    <div>
      <div
        onClick={onClose}
        className="bg-[#1f1f1f]/50 absolute top-0 right-0 bottom-0 left-0"></div>
      <div
        className={`absolute top-0 right-0 bottom-0 bg-[#1f1f1f] border-l border-[#B67E4F] p-4 w-full z-50 drop-shadow section space-y-3 ${width}`}>
        {children}
      </div>
    </div>
  )
}

export default SlideOver;
