import {createSlice} from "@reduxjs/toolkit";
import {RootState} from "./index";

export interface ChatMessage {
  userId: string;
  userName: string;
  body: string;
}

export interface ChatGroup {
  groupId: string;
  name: string;
  messages: ChatMessage[] | undefined;
}

export interface CommunicationState {
  chatGroups: ChatGroup[];
}

const initialState: CommunicationState = {
  chatGroups: [],
};

export interface ChatMessageReceivedPayload {
  groupId: string;
  message: string;
  userId: string;
  userName: string;
  chatTitle: string;
}

export interface UpdateRecentChatsPayload {
  groupId: string;
  name: string;
}

export interface UpdateFullChatMessagesPayload {
  groupId: string;
  messages: ChatMessage [];
}

export const communicationSlice = createSlice({
  name: 'communication',
  initialState,
  reducers: {
    updateRecentChats: (state, action: { payload: UpdateRecentChatsPayload[] }) => {
      action.payload.forEach(chatGroup => {
        state.chatGroups.push({
          groupId: chatGroup.groupId,
          name: chatGroup.name,
          messages: undefined,
        });
      });
    },
    updateFullChatMessages: (state, action: { payload: UpdateFullChatMessagesPayload }) => {
      const existingGroup = state.chatGroups.find((x: ChatGroup) => x.groupId === action.payload.groupId);
      if (existingGroup) {
        existingGroup.messages = action.payload.messages;
      }
    },
    messageReceived: (state, action: { payload: ChatMessageReceivedPayload }) => {
      const existingGroup = state.chatGroups.find((x: ChatGroup) => x.groupId === action.payload.groupId);
      if (existingGroup) {
        if (existingGroup.messages) {
          existingGroup.messages.push({
            userId: action.payload.userId,
            userName: action.payload.userName,
            body: action.payload.message,
          });
        }
        // No else, load all messages on opening chat
      } else {
        state.chatGroups.push({
          groupId: action.payload.groupId,
          name: action.payload.chatTitle,
          messages: undefined,
        });
      }
    },
  },
});

export const {
  messageReceived,
  updateRecentChats,
  updateFullChatMessages,
} = communicationSlice.actions;

export const selectCommunicationChatGroups = (state: RootState) => state.communication.chatGroups;

export default communicationSlice.reducer;
