
const AccountPage = () => {

  return (
    <div className="flex flex-col xl:flex-row w-full gap-3">
      <div className="section rounded p-3 w-full">
        <div className="container mx-auto">
          account info goes here jake..
        </div>
      </div>
    </div>
  );
}

export default AccountPage;