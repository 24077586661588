import React from "react";
import { useAuth } from 'oidc-react';

const LoginCallbackPage = () => {
  const auth = useAuth();

  if (!auth || !auth.userData) {
    return (<div>Not logged in!</div>);
  }

  return (<div>
    Logged in!
    Subject: {auth.userData.profile.sub}
  </div>);
};

export default LoginCallbackPage;