import {withAuth} from "oidc-react";
import {useCallback, useContext, useEffect, useMemo, useState} from "react";
import {Crime} from "../types/Crime";
import {SignalRContext} from "../SignalRContext";
import {CrimeFamily} from "../types/CrimeFamily";
import Button, {ButtonType} from "../components/Button";
import {CrimeResult} from "../types/CrimeResult";
import {CrimeOutcome} from "../types/CrimeOutcome";

function CrimesPage() {
  const signalR = useContext(SignalRContext);
  const [crimes, setCrimes] = useState<Crime[]>([]);
  const [selectedCrimeFamily, setSelectedCrimeFamily] = useState(CrimeFamily.Blackmail);
  const [selectedFamilyCrimes, setSelectedFamilyCrimes] = useState<Crime[]>([]);
  const [crimeResult, setCrimeResult] = useState<CrimeResult | undefined>(undefined);
  const [lastCommitedCrimeId, setLastCommitedCrimeId] = useState<string | undefined>(undefined);

  useMemo(() => {
    const filteredCrimes = crimes.filter(x => x.family === selectedCrimeFamily);
    setSelectedFamilyCrimes(filteredCrimes);
  }, [crimes, selectedCrimeFamily]);

  const loadCrimeDataAsync = useCallback(async () => {
    const crimesList = await signalR.invoke('GetCrimeList');
    setCrimes(crimesList);
  }, [signalR]);

  useEffect(() => {
    (async function () {
      await loadCrimeDataAsync();
    })();
  }, [loadCrimeDataAsync]);

  const onClickCommitCrime = async (crimeId: string) => {
    setLastCommitedCrimeId(undefined);
    const crimeResult = await signalR.invoke('CommitCrime', crimeId);
    setCrimeResult(crimeResult);
    setLastCommitedCrimeId(crimeId);
  }

  return (
    <div className="grid grid-cols-12 gap-3 w-full">
      <div className="rounded section col-span-3 h-auto overflow-y-auto">
        <ul className="section_list_crime divide-y divide-[#171716]">
          {Object.keys(CrimeFamily)
            .filter(v => isNaN(Number(v)))
            .map(crimeFamily => (<li className="w-full text-center flex" key={crimeFamily}
                                     onClick={() => setSelectedCrimeFamily(CrimeFamily[crimeFamily as any] as unknown as CrimeFamily)}>
              <span className="m-auto font-bold">{crimeFamily}</span>
            </li>))}
        </ul>
      </div>
      <div className="grid col-span-9 gap-3 self-start">
        {
          selectedFamilyCrimes && selectedFamilyCrimes.map((crime) => (
            <div className="flex flex-col section rounded p-3 divide-y divide-[#171716]">
              <div className="flex justify-between pb-1">
                <div className="flex flex-col text-xs">
                  <span className="text-sm text-[#cfcdbf] font-light">{crime.name}</span>
                  <span className="text-[#cfcdbf] font-extralight">{crime.description}</span>
                  <span className="text-[#BE8250FF] font-extralight">{crime.courageCost} Courage</span>
                </div>
                <div className="self-center text-sm">
                  <Button type={ButtonType.DEAFULT} onClick={() => onClickCommitCrime(crime.id)}  className="ml-auto">
                    Commit
                  </Button>
                </div>
              </div>
              {lastCommitedCrimeId === crime.id && crimeResult &&
                <div className="flex flex-col gap-3 text-[#cfcdbf] text-xs pt-1">
                    <div className="flex flex-col gap-1">
                        <span className="text-emerald-600 text-sm font-light">{CrimeOutcome[crimeResult.outcome]}</span>
                        <p className="text-[#cfcdbf] font-light">{crimeResult.title}</p>
                        <p className="text-[#cfcdbf] font-extralight">{crimeResult.description}</p>
                    </div>
                    <div className="flex gap-1">
                      {/*todo - crime rewards names - talk to tom.*/}
                    {/*<div className="px-2 py-px text-[#0C0C0CCC] section_orange bg-[#BE8250FF] rounded">*/}
                    {/*  $1,400*/}
                    {/*</div>*/}
                    {/*<div className="px-2 py-px text-[#0C0C0CCC] section_orange bg-[#BE8250FF] rounded">*/}
                    {/*  244xp*/}
                    {/*</div>*/}
                  </div>
                </div>
              }
            </div>
          ))
        }
      </div>
    </div>
  );
}

export default withAuth(CrimesPage);