import {useDebounceCallback} from "usehooks-ts";
import {useContext, useEffect, useState} from "react";
import {SignalRContext} from "../SignalRContext";
import {withAuth} from "oidc-react";
import {useNavigate} from "react-router-dom";

function CreateProfile() {
  const signalR = useContext(SignalRContext);
  const [username, setUsername] = useState('');
  const [usernameTaken, setUsernameTaken] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    (async function () {
      try {
        if (username.trim().length === 0) {
          setUsernameTaken(true);
          return;
        }
        const usernameTakenData = await signalR
          .invoke('CheckUsernameAvailability', username.trim());

        setUsernameTaken(!usernameTakenData);
      } catch (e) {
        console.log(e)
      }
    })();
  }, [signalR, username]);
  const debounced = useDebounceCallback(setUsername, 500);

  const onCreateUser = async (ev: any) => {
    ev.preventDefault();
    const userCreated = await signalR
      .invoke('CreateUser', username.trim());
    if (userCreated) {
      navigate('/game');
    }
  };

  return (
    <div className="relative w-screen h-screen flex justify-center">
      <div
        className="absolute z-50 section rounded p-3 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-full lg:w-auto">
        <div className="flex h-full w-full">
          <div className="flex flex-col gap-3 m-auto self-center w-full">
            <h1 className="text-[#cfcdbf] text-4xl font-thin">Welcome to Tidal City</h1>
            <form className="flex justify-center w-full" onSubmit={onCreateUser}>
              <div className="flex flex-col w-full text-left">
                <label className="font-light text-[#cfcdbf]" htmlFor="username">Pick a name</label>
                <p className="text-[#cfcdbf] italic text-xs mb-2">This is the name other players will know you by in the
                  game</p>
                <input
                  name="username"
                  className="p-1 rounded-sm bg-transparent focus:outline-0 border border-[#BE8250] border-opacity-50 focus:border-opacity-100 ease-in-out duration-300 text-[#cfcdbf]"
                  defaultValue={username}
                  onChange={event => debounced(event.target.value)}/>
                <button
                  type="submit"
                  className="create_profile_button mt-2 enabled:cursor-pointer"
                  disabled={usernameTaken}>Create
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <img className="object-cover w-full object-top opacity-40 pointer-events-none blur-[2px]" src="https://imgur.com/nwPzijh.png" alt="hero"/>
    </div>
  );
}

export default withAuth(CreateProfile);