import React from "react";

export enum ButtonType {
  DEAFULT = "button_orange rounded px-2",
  DARK = 'button_default rounded px-2',
  LIGHT = 'button_light rounded px-2',
  DANGER = 'button_danger rounded px-2',
}

interface ButtonProps {
  className?: string;
  type?: ButtonType;
  onClick: () => void;
}

function Button({ className, children, type = ButtonType.DARK, onClick}: React.PropsWithChildren<ButtonProps>) {
  return (
    <div className={`${className} ${type}`} onClick={onClick}>
      {children}
    </div>
  );
}

export default Button;
