import Card, {CardPadding, CardStyle} from "../components/Card";
import {useCallback, useContext, useEffect, useState} from "react";
import {SignalRContext} from "../SignalRContext";
import SlideOver from "../components/SlideOver";
import {Gym} from "../types/Gym";
import {withAuth} from "oidc-react";
import Button, {ButtonType} from "../components/Button";
import {UserGymPass} from "../types/UserGymPass";
import {FiChevronRight} from "react-icons/fi";

function GymPage() {
  const signalR = useContext(SignalRContext);
  const [showSelectedGymPass, setSelectedGymPass] = useState<string | undefined>(undefined);
  const [checkUserGymAccess, setUserGymAccess] = useState(false);
  const [userGymPasses, setUserGymPasses] = useState<UserGymPass[]>([]);
  const [localGyms, setLocalGyms] = useState<Gym[]>([]);
  const [selectedGym, setSelectedGym] = useState<Gym>();
  const [showGymPasses, setShowGymPasses] = useState(false);

  const loadGymPassesForUser = useCallback(async () => {
    const gymPasses = await signalR.invoke('GetGymPasses');
    setUserGymPasses(gymPasses);
  }, [signalR]);

  const loadLocalGyms = useCallback(async () => {
    const gyms = await signalR.invoke('GetLocalGyms');
    setLocalGyms(gyms);
  }, [signalR]);

  useEffect(() => {
    (async function () {
      await loadGymPassesForUser();
      await loadLocalGyms();
    })();
  }, [loadGymPassesForUser, loadLocalGyms]);

  const onClickPurchaseGym = async (gymId: string, passId: string) => {
    await signalR.invoke('`BuyGymPass`', gymId, passId)
  }

  const onClickSelectGym = async (gym:Gym) => {
    setSelectedGym(gym);
    const userHasAccess = await signalR.invoke('CheckGymAccess', gym.id)
    setUserGymAccess(userHasAccess);
  }

  return (
    <div className="flex flex-col gap-3">
      <Card className="flex flex-col gap-3">
        <div className="flex w-full justify-between cursor-pointer" onClick={() => setShowGymPasses(!showGymPasses)}>
          <span className="text-[#cfcdbf] font-light text-sm whitespace-nowrap">Your Gym Passes</span>
          <FiChevronRight className={showGymPasses ? "h-5 w-5 rotate-90 ease-in-out duration-300" : "h-5 w-5 ease-in-out duration-300"}/>
        </div>
        {showGymPasses &&
          <div className="grid grid-cols-6 text-xs">
            {userGymPasses.map(gymPass => (
                <Card className="flex flex-col" type={CardStyle.ORANGE} padding={CardPadding.XSMALL}>
                <span>{gymPass.name}</span>
                <span className="text-xs">
                  {gymPass.expiresAt && new Date(gymPass.expiresAt) > new Date() &&
                      <div>Expires at {new Date(gymPass.expiresAt).toLocaleString()}</div>
                  }
                  {gymPass.expiresAt && new Date(gymPass.expiresAt) <= new Date() &&
                    "Expired"
                  }
                </span>
              </Card>
            ))}
          </div>
        }
      </Card>
      <div className="grid grid-cols-1 xl:grid-cols-12 gap-3 w-full">
        <div className="rounded section col-span-12 xl:col-span-3 overflow-y-auto self-start">
          <ul className="section_list_gym divide-y divide-[#171716]">
            {localGyms &&
              localGyms.map(gym => (
                <li onClick={() => onClickSelectGym(gym)} className="w-full text-center flex">
                  <span className="m-auto font-bold">{gym.name}</span>
                </li>
              ))
            }
          </ul>
        </div>
        {selectedGym &&
            <div className="grid col-span-12 xl:col-span-9 gap-3 xl:self-start">
              <div className="grid grid-cols-4 gap-3">
                {selectedGym.passes.map(gymPass => (
                  <Card className="flex flex-col gap-1" type={CardStyle.ORANGE}>
                    <span className="text-xs font-semibold">{gymPass.name}</span>
                    <span className="text-xs">${gymPass.cost}</span>
                    <Button type={ButtonType.DARK} onClick={() => onClickPurchaseGym(selectedGym.id, gymPass.id)} className="mt-auto ml-auto w-min">
                      Buy
                    </Button>
                  </Card>
                ))}
              </div>
              {checkUserGymAccess && selectedGym.activities.map(activity => (
                <Card padding={CardPadding.SMALL} className="flex flex-col">
                  <div className="flex pb-1">
                    <div className="flex flex-col text-xs">
                      <span className="text-sm text-[#cfcdbf] font-light">{selectedGym.name}</span>
                      <span className="text-[#cfcdbf] font-extralight">{activity.name} - <span
                        className="text-[#BE8250FF] font-extralight">{activity.cost.stamina} Stamina per Train</span></span>
                      <div className="flex gap-2 pt-2">
                        {activity.modifiers.accuracy > 0 &&
                            <Card className="px-2" padding={CardPadding.NONE} type={CardStyle.ORANGE}>
                                <span>Accuracy</span>
                            </Card>
                        }
                        {activity.modifiers.defense > 0 &&
                            <Card className="px-2" padding={CardPadding.NONE} type={CardStyle.ORANGE}>
                                <span>Defense</span>
                            </Card>
                        }
                        {activity.modifiers.attack > 0 &&
                            <Card className="px-2" padding={CardPadding.NONE} type={CardStyle.ORANGE}>
                                <span>Attack</span>
                            </Card>
                        }
                        {activity.modifiers.agility > 0 &&
                            <Card className="px-2" padding={CardPadding.NONE} type={CardStyle.ORANGE}>
                                <span>Agility</span>
                            </Card>
                        }
                      </div>
                    </div>
                    <div className="flex gap-1 ml-auto mt-auto">
                      <input type="number" placeholder="1" min="0" className="flex self-center h-6 px-2 bg-transparent border border-[#BE8250FF] outline-none text-[#cfcdbf] text-xs rounded"/>
                      <Button type={ButtonType.DEAFULT} onClick={() => {}}>Train</Button>
                    </div>
                  </div>
                  {/*  outcome here*/}
                </Card>
              ))}
            </div>
        }
      </div>
      {showSelectedGymPass &&
          <SlideOver onClose={() => setSelectedGymPass(undefined)}>
              gym pass info
          </SlideOver>
      }
    </div>
  );
}

export default withAuth(GymPage);