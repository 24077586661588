import React from "react";
export enum CardStyle {
  DEFAULT = "section rounded text-[#cfcdbf]",
  ORANGE = "section_orange rounded text-[#1f1f1f]",
}

export enum CardPadding {
  NONE = "",
  XSMALL = "p-1",
  SMALL = "p-2",
  DEFAULT = "p-3",
  LARGE = "p-4"
}

interface CardProps {
  type?: CardStyle;
  padding?: CardPadding;
  className?: string;
}

function Card({ padding = CardPadding.DEFAULT, type = CardStyle.DEFAULT, children, className }: React.PropsWithChildren<CardProps>) {
  return (
    <div className={`${type} ${padding} ${className}`}>
      {children}
    </div>
  );
}

export default Card;
