import {useAuth} from "oidc-react";

const IndexPage = () => {
  const auth = useAuth();

  return (<div>
    Hello World!
    <button onClick={() => auth.signIn()}>Login</button>
    <button onClick={auth.signOut}>Logout</button>
  </div>);
};

export default IndexPage;