import {useMemo, useState} from "react";

interface VitalBarProps {
  title?: string;
  minValue: number;
  maxValue: number;
  color: string;
}
function VitalBar (props: VitalBarProps) {
  const [boxColor, setBoxColor] = useState<string>("unset");

  useMemo(() => {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(props.color);
    if (result === null) { return; }
    setBoxColor(`0 4px 30px rgba(${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(result[3], 16)}, 0.46)`);
  },[setBoxColor, props])

  return (
    <div className="w-full text-[#cfcdbf]">
      <div className="flex justify-between text-xs">
        <label className="text-xs ">{props.title}</label>
        <span>{props.minValue} / {props.maxValue}</span>
      </div>
      <div className="relative bg-[#242526] h-2 overflow-hidden">
        <div className="flex w-full h-full" style={{ backgroundColor: props.color, boxShadow: boxColor, transform:`translateX(-${100 - ((props.minValue / props.maxValue) * 100)}%)` }}></div>
      </div>
    </div>
  )
}

export default VitalBar