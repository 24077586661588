import {createSlice} from "@reduxjs/toolkit";
import {RootState} from "./index";

export interface BattleStatistics {
  attack: number;
  defense: number;
  agility: number;
  accuracy: number;
}

export interface Employment {
  companyIds: string[];
}

export interface Experience {
  level: number;
  crime: number;
}

export interface Wallets {
  tidalTokens: number;
  usd: number;
}

export interface Vitals {
  health: number;
  courage: number;
  stamina: number;
  wellBeing: number;
}

export interface User {
  name: string;
  donatorUntil: Date;
  created: Date;
  hospitalizedUntil: Date;
  jailedUntil: Date;
  battleStatistics: BattleStatistics;
  employment: Employment;
  experience: Experience;
  level: number;
  wallets: Wallets;
  vitals: Vitals;
  maxVitals: Vitals;
}

export interface UserState {
  user?: User;
}

const initialState: UserState = {
  user: undefined,
}

export interface UpdateUserVitalsPayload {
  courage?: number;
  stamina?: number;
  wellBeing?: number;
  health?: number;
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload.user;
    },
    updateWalletUsd: (state, action: { payload: number }) => {
      if (!state.user) {
        return;
      }
      state.user.wallets.usd = action.payload;
    },
    updateWalletTidalTokens: (state, action: { payload: number }) => {
      if (!state.user) {
        return;
      }
      state.user.wallets.tidalTokens = action.payload;
    },
    updateUserVitals: (state, action: { payload: UpdateUserVitalsPayload }) => {
      if (!state.user) {
        return;
      }
      if (action.payload.courage !== undefined) {
        state.user.vitals.courage = action.payload.courage;
      }
      if (action.payload.stamina !== undefined) {
        state.user.vitals.stamina = action.payload.stamina;
      }
      if (action.payload.health !== undefined) {
        state.user.vitals.health = action.payload.health;
      }
      if (action.payload.wellBeing !== undefined) {
        state.user.vitals.wellBeing = action.payload.wellBeing;
      }
    },
  },
});

export const {
  setUser,
  updateWalletUsd,
  updateWalletTidalTokens,
  updateUserVitals,
} = userSlice.actions;

export const selectUser = (state: RootState) => state.user.user;

export default userSlice.reducer;
