import {useMemo, useState} from "react";
import {useAppSelector} from "../state/hooks";
import {selectUser} from "../state/user";

const HomePage = () => {
  const user = useAppSelector(selectUser);
  const [age, setAge] = useState<number>()
  const [showAttackStats, setShowAttackStats] = useState<boolean>(false);
  const [showCrimeStats, setShowCrimeStats] = useState<boolean>(false);
  
  useMemo(() => {
    if (!user) {
      return;
    }
    const createdDate = new Date(user!.created);
    const today = new Date()
    const diffTime = Math.abs(today.valueOf() - createdDate.valueOf());
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    setAge(diffDays);
  }, [user]);
  
  return (
    <div className="flex flex-col xl:flex-row w-full gap-3">
      <div className="flex flex-col gap-3 w-full">
        <div className="section rounded text-[#cfcdbf]">
          <div className="border-b-[1px] border-black border-opacity-50 p-2">
            <span className="font-bold">Profile</span>
          </div>
          <div className="border-t-[1px] border-[#B67E4F] border-opacity-15">
            <div className="grid grid-cols-2 text-xs">
              <span className="p-2 border-r border-b border-[#171716]">
                Name
              </span>
              <span className="p-2 border-b border-[#171716] text-[#BE8250FF]">
                {user?.name}
              </span>
              <span className="p-2 border-r border-b border-[#171716]">
                Level
              </span>
              <span className="p-2 border-b border-[#171716] text-[#BE8250FF]">
                {user?.level}
              </span>
              <span className="p-2 border-r border-b border-[#171716]">
                Health
              </span>
              <span className="p-2 border-b border-[#171716] text-[#BE8250FF]">
                {user?.vitals?.health} / {user?.maxVitals?.health}
              </span>
              <span className="p-2 border-r border-b border-[#171716]">
                Age
              </span>
              <span className="p-2 border-b border-[#171716] text-[#BE8250FF]">
                {age}
              </span>
            </div>
          </div>
        </div>
        <div className="section rounded text-[#cfcdbf]">
          <div className="border-b-[1px] border-black border-opacity-50 p-2">
            <span className="font-bold">Profile</span>
          </div>
          <div className="border-t-[1px] border-[#B67E4F] border-opacity-15">
            <div className="grid grid-cols-2 text-xs">
              <span className="p-2 border-r border-b border-[#171716]">
                Name
              </span>
              <span className="p-2 border-b border-[#171716] text-[#BE8250FF]">
                {user?.name}
              </span>
              <span className="p-2 border-r border-b border-[#171716]">
                Level
              </span>
              <span className="p-2 border-b border-[#171716] text-[#BE8250FF]">
                {user?.level}
              </span>
              <span className="p-2 border-r border-b border-[#171716]">
                Health
              </span>
              <span className="p-2 border-b border-[#171716] text-[#BE8250FF]">
                {user?.vitals?.health} / {user?.maxVitals?.health}
              </span>
              <span className="p-2 border-r border-b border-[#171716]">
                Age
              </span>
              <span className="p-2 border-b border-[#171716] text-[#BE8250FF]">
                {age}
              </span>
            </div>
          </div>
        </div>
        <div className="section rounded text-[#cfcdbf]">
          <div className="border-b-[1px] border-black border-opacity-50 p-2">
            <span className="font-bold">Profile</span>
          </div>
          <div className="border-t-[1px] border-[#B67E4F] border-opacity-15">
            <div className="grid grid-cols-2 text-xs">
              <span className="p-2 border-r border-b border-[#171716]">
                Name
              </span>
              <span className="p-2 border-b border-[#171716] text-[#BE8250FF]">
                {user?.name}
              </span>
              <span className="p-2 border-r border-b border-[#171716]">
                Level
              </span>
              <span className="p-2 border-b border-[#171716] text-[#BE8250FF]">
                {user?.level}
              </span>
              <span className="p-2 border-r border-b border-[#171716]">
                Health
              </span>
              <span className="p-2 border-b border-[#171716] text-[#BE8250FF]">
                {user?.vitals?.health} / {user?.maxVitals?.health}
              </span>
              <span className="p-2 border-r border-b border-[#171716]">
                Age
              </span>
              <span className="p-2 border-b border-[#171716] text-[#BE8250FF]">
                {age}
              </span>
            </div>
          </div>
        </div>
        <div className="section rounded text-[#cfcdbf]">
          <div className="border-b-[1px] border-black border-opacity-50 p-2">
            <span className="font-bold">Profile</span>
          </div>
          <div className="border-t-[1px] border-[#B67E4F] border-opacity-15">
            <div className="grid grid-cols-2 text-xs">
              <span className="p-2 border-r border-b border-[#171716]">
                Name
              </span>
              <span className="p-2 border-b border-[#171716] text-[#BE8250FF]">
                {user?.name}
              </span>
              <span className="p-2 border-r border-b border-[#171716]">
                Level
              </span>
              <span className="p-2 border-b border-[#171716] text-[#BE8250FF]">
                {user?.level}
              </span>
              <span className="p-2 border-r border-b border-[#171716]">
                Health
              </span>
              <span className="p-2 border-b border-[#171716] text-[#BE8250FF]">
                {user?.vitals?.health} / {user?.maxVitals?.health}
              </span>
              <span className="p-2 border-r border-b border-[#171716]">
                Age
              </span>
              <span className="p-2 border-b border-[#171716] text-[#BE8250FF]">
                {age}
              </span>
            </div>
          </div>
        </div>
        <div className="section rounded text-[#cfcdbf]">
          <div className="border-b-[1px] border-black border-opacity-50 p-2">
            <span className="font-bold">Profile</span>
          </div>
          <div className="border-t-[1px] border-[#B67E4F] border-opacity-15">
            <div className="grid grid-cols-2 text-xs">
              <span className="p-2 border-r border-b border-[#171716]">
                Name
              </span>
              <span className="p-2 border-b border-[#171716] text-[#BE8250FF]">
                {user?.name}
              </span>
              <span className="p-2 border-r border-b border-[#171716]">
                Level
              </span>
              <span className="p-2 border-b border-[#171716] text-[#BE8250FF]">
                {user?.level}
              </span>
              <span className="p-2 border-r border-b border-[#171716]">
                Health
              </span>
              <span className="p-2 border-b border-[#171716] text-[#BE8250FF]">
                {user?.vitals?.health} / {user?.maxVitals?.health}
              </span>
              <span className="p-2 border-r border-b border-[#171716]">
                Age
              </span>
              <span className="p-2 border-b border-[#171716] text-[#BE8250FF]">
                {age}
              </span>
            </div>
          </div>
        </div>
        <div className="section rounded text-[#cfcdbf]">
          <div className="border-b-[1px] border-black border-opacity-50 p-2">
            <span className="font-bold">Property</span>
          </div>
          <div className="border-t-[1px] border-[#B67E4F] border-opacity-15"></div>
          <ul className="flex section_list_row divide-x divide-[#171716] border-b-[1px] border-black border-opacity-50">
            <li className="w-full flex justify-center relative group">
              <div className="absolute -top-1 -left-1">
                <div
                  className="h-3 w-3 news_alert rounded-full flex animate-pulse duration-300 group-hover:animate-none">
                </div>
              </div>
              Pay ($15)
            </li>
            <li className="w-full flex justify-center">
              Upgrades
            </li>
            <li className="w-full flex justify-center">
              View All
            </li>
          </ul>
          <div className="border-t-[1px] border-[#B67E4F] border-opacity-15"></div>
          <div className="m-1 relative border border-[#B67E4F] border-opacity-15">
            <div className="property-image"></div>
          </div>
          <div className="border-b-[1px] border-black border-opacity-50"></div>
          <div className="border-t-[1px] border-[#B67E4F] border-opacity-15">
            <div className="grid grid-cols-2 text-xs">
              <span className="p-2 border-r border-b border-[#171716]">
                Property
              </span>
              <span className="p-2 border-b border-[#171716] text-[#BE8250FF]">
                Shack
              </span>
              <span className="p-2 border-r border-b border-[#171716]">
                Price
              </span>
              <span className="p-2 border-b border-[#171716] text-[#BE8250FF]">
                $1,000
              </span>
              <span className="p-2 border-r border-b border-[#171716]">
                Maintenance
              </span>
              <span className="p-2 border-b border-[#171716] text-[#BE8250FF]">
                $15
              </span>
              <span className="p-2 border-r border-b border-[#171716]">
                Owner
              </span>
              <span className="p-2 border-b border-[#171716] text-[#BE8250FF]">
                JKE
              </span>
              <span className="p-2 border-r border-b border-[#171716]">
                Well-being
              </span>
              <span className="p-2 border-b border-[#171716] text-[#BE8250FF]">
                10
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-3 w-full">
        <div className="section rounded text-[#cfcdbf]">
          <div className="border-b-[1px] border-black border-opacity-50 p-2">
            <span className="font-bold">Equipment</span>
          </div>
          <div className="border-t-[1px] border-[#B67E4F] border-opacity-15"></div>
          <ul className="flex section_list_row divide-x divide-[#171716] border-b-[1px] border-black border-opacity-50">
            <li className="w-full flex justify-center relative group">
              Manage
            </li>
          </ul>
          <div className="border-t-[1px] border-[#B67E4F] border-opacity-15">
            <div className="grid grid-cols-4 text-xs">
              <span className="p-2 border-r border-b border-[#171716] select_effect">
                <div className="p-4">

                </div>
              </span>
              <span className="p-2 border-r border-b border-[#171716] select_effect">
                <div className="p-4">

                </div>
              </span>
              <span className="p-2 border-r border-b border-[#171716] select_effect">
                <div className="p-4">

                </div>
              </span>
              <span className="p-2 border-b border-[#171716] select_effect">
                <div className="p-4">

                </div>
              </span>
              <span className="p-2 border-r border-b border-[#171716] select_effect">
                <div className="p-4">

                </div>
              </span>
              <span className="p-2 border-r border-b border-[#171716] select_effect">
                <div className="p-4">

                </div>
              </span>
              <span className="p-2 border-r border-b border-[#171716] select_effect">
                <div className="p-4">

                </div>
              </span>
              <span className="p-2 border-b border-[#171716] select_effect">
                <div className="p-4">

                </div>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-3 w-full">
        <div className="section rounded text-[#cfcdbf]">
          <div className="border-b-[1px] border-black border-opacity-50 p-2">
            <span className="font-bold">Skills</span>
          </div>
          <div className="border-t-[1px] border-[#B67E4F] border-opacity-15">
            <div className="grid grid-cols-2 text-xs">
              <span className="p-2 border-r border-b border-[#171716]">
                Total Experience
              </span>
              <span className="p-2 border-b border-[#171716] text-[#BE8250FF]">
                {user?.experience.level}
              </span>
              <span className="p-2 border-r border-b border-[#171716]">
                Life
              </span>
              <span className="p-2 border-b border-[#171716] text-[#BE8250FF]">
                100
              </span>
              <div className="relative border-b border-[#171716] grid grid-cols-2 items-center col-span-full">
                <span className="p-2 border-r border-[#171716] px-2">
                  Attack
                </span>
                <div onClick={() => setShowAttackStats(!showAttackStats)}
                     className="flex justify-between p-2 text-[#BE8250FF] cursor-pointer group">
                  <span>0</span>
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                       stroke="currentColor"
                       className={showAttackStats ? 'w-4 h-4 rotate-90 ease-in-out duration-300' : 'w-4 h-4 group-hover:rotate-12 ease-in-out duration-300'}>
                    <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5"/>
                  </svg>
                </div>
                {
                  showAttackStats &&
                    <div className="col-span-full border-t border-[#171716] grid grid-cols-2 bg-[#B67E4FFF]/15">
                      <span className="p-2 px-4 border-r border-[#171716]">
                        Rifle
                      </span>
                        <span className="flex p-2 text-[#BE8250FF]">
                        0
                      </span>
                        <span className="p-2 px-4 border-t border-r border-[#171716]">
                        Handgun
                      </span>
                        <span className="flex p-2 border-t border-[#171716] text-[#BE8250FF]">
                        0
                      </span>
                        <span className="p-2 px-4 border-r border-t border-[#171716]">
                        Shotgun
                      </span>
                        <span className="flex p-2 border-t border-[#171716] text-[#BE8250FF]">
                        0
                      </span>
                    </div>
                }
              </div>

              <span className="p-2 border-r border-b border-[#171716]">
                Defense
              </span>
              <span className="p-2 border-b border-[#171716] text-[#BE8250FF]">
                1
              </span>
              <span className="p-2 border-r border-b border-[#171716]">
                Agility
              </span>
              <span className="p-2 border-b border-[#171716] text-[#BE8250FF]">
                1
              </span>
              <span className="p-2 border-r border-b border-[#171716]">
                Accuracy
              </span>
              <span className="p-2 border-b border-[#171716] text-[#BE8250FF]">
                1
              </span>
              <div className="relative border-b border-[#171716] grid grid-cols-2 items-center col-span-full">
                <span className="p-2 border-r border-[#171716] px-2">
                  Crime
                </span>
                <div onClick={() => setShowCrimeStats(!showCrimeStats)}
                     className="flex justify-between p-2 text-[#BE8250FF] cursor-pointer group">
                  <span>0</span>
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                       stroke="currentColor"
                       className={showCrimeStats ? 'w-4 h-4 rotate-90 ease-in-out duration-300' : 'w-4 h-4 group-hover:rotate-12 ease-in-out duration-300'}>
                    <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5"/>
                  </svg>
                </div>
                {
                  showCrimeStats &&
                    <div className="col-span-full border-t border-[#171716] grid grid-cols-2 bg-[#B67E4FFF]/15">
                      <span className="p-2 px-4 border-r border-[#171716]">
                        Blackmail
                      </span>
                        <span className="flex p-2 text-[#BE8250FF]">
                        0
                      </span>
                    </div>
                }
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  );
}

export default HomePage;