import Card, {CardPadding} from "../components/Card";
import React, {useCallback, useContext, useEffect, useState} from "react";
import {SignalRContext} from "../SignalRContext";
import {withAuth} from "oidc-react";
import {StorageType, UserStorageSummary} from "../types/UserStorageSummary";
import Button, {ButtonType} from "../components/Button";
import {GameItem} from "../types/userStorageInventoryItem";
import {FiDollarSign, FiSend, FiTrash} from "react-icons/fi";
import {GameItemMetaDataType} from "../types/GameItemMetaDataType";

function InventoryPage() {
  const [availableStorageLocations, setAvailableStorageLocations] = useState<UserStorageSummary[]>([]);
  const [storageItems, setStorageItems] = useState<GameItem[]>([])
  const signalR = useContext(SignalRContext);
  
  const loadAvailableStorageLocations = useCallback(async () => {
    const storageLocations = await signalR.invoke('GetAvailableStorageLocations');
    setAvailableStorageLocations(storageLocations);
  }, [signalR]);
  
  const loadStorageItems = useCallback(async (storageId: string) => {
    const items = await signalR.invoke('GetStorageItems', storageId);
    setStorageItems(items);
  }, [signalR]);
  
  const equipItem = async (gameItemId: string) => {
    await signalR.invoke('EquipGameItem', availableStorageLocations[0].id, gameItemId)
  }
  
  useEffect(() => {
    (async function () {
      await loadAvailableStorageLocations();
    })();
  }, [loadAvailableStorageLocations]);
  
  useEffect(() => {
    if (availableStorageLocations.length > 0) {
      (async function () {
        await loadStorageItems(availableStorageLocations[0].id);
      })();
    }
  }, [availableStorageLocations, loadStorageItems]);
  return <div className="flex flex-col gap-3">
    <Card padding={CardPadding.NONE} className="flex flex-col">
      <span className="text-[#cfcdbf] font-light text-sm whitespace-nowrap p-2">Your Inventory</span>
    </Card>
    <div className="grid grid-cols-1 xl:grid-cols-12 gap-3 w-full">
      <Card className="flex flex-col gap-3 rounded section col-span-12 xl:col-span-3 overflow-y-auto self-start">
        {availableStorageLocations &&
          availableStorageLocations.map(storageLocation => <Button className="flex gap-2 h-12 items-center xl:w-full"
                                                                   type={ButtonType.DARK}
                                                                   onClick={() => loadStorageItems(storageLocation.id)}>
            <img className="h-8 w-8 border border-[#ecb274] object-cover rounded my-auto"
                 src={storageLocation.storageType === 0 ? 'https://imgur.com/LhgYrbl.png' : 'https://imgur.com/eVJ3xwl.png'}
                 alt="inventory type"/>
            <span className="my-auto text-xs">{StorageType[storageLocation.storageType]}</span>
          </Button>)
        }
      </Card>
      <Card padding={CardPadding.NONE} className="flex flex-col col-span-9 self-start">
        {!storageItems.length &&
          <span className="text-xs p-3">No items in your inventory.</span>
        }
        {storageItems.map(x => (
          <div
            className="flex text-xs p-3 first-of-type:border-b first-of-type:border-black/50 first-of-type:border-t-0 border-t border-[#B67E4F] border-opacity-15">
            <div className="flex flex-col gap-1 self-center">
              <span>{x.gameItem.name} x {x.quantity}</span>
              <div className="flex gap-1">
                {x.gameItem.metadata &&
                  x.gameItem.metadata.map((x) => (
                    <span className="cursor-pointer hover-underline-animation relative lowercase first-letter:capitalize">
                      {GameItemMetaDataType[x.type]}: {x.value}
                    </span>
                  ))
                  // <span className="cursor-pointer hover-underline-animation relative">Equip</span>
                }
              </div>
              <div className="flex gap-1">
                {x.gameItem.equippable &&
                    <span onClick={() => equipItem(x.gameItem.id)}
                          className="cursor-pointer hover-underline-animation relative">Equip</span>
                }
              </div>
            </div>
            <div className="flex gap-1 ml-auto self-end">
              <Button
                className="flex py-2 px-3 ml-auto hover:after:text-xs hover:after:-top-5 hover:after:left-1/2 hover:after:transform hover:after:-translate-x-1/2 hover:after:content-['Send'] hover:after:absolute hover:after:bg-[#cfcdbf] hover:after:shadow-2xl hover:after:section hover:after:px-1 hover:after:rounded-sm hover:before:p-1 hover:before:absolute hover:before:bg-[#cfcdbf] hover:before:-top-[11px] hover:before:rotate-45 hover:before:left-1/2 hover:before:-translate-x-1/2"
                type={ButtonType.LIGHT}
                onClick={() => {
                }}
              >
                <FiSend className="m-auto"/>
              </Button>
              <Button
                className="flex py-2 px-3 ml-auto hover:after:text-xs hover:after:-top-5 hover:after:left-1/2 hover:after:transform hover:after:-translate-x-1/2 hover:after:content-['Quick\00a0Sell'] hover:after:absolute hover:after:bg-[#cfcdbf] hover:after:shadow-2xl hover:after:section hover:after:px-1 hover:after:rounded-sm hover:before:p-1 hover:before:absolute hover:before:bg-[#cfcdbf] hover:before:-top-[11px] hover:before:rotate-45 hover:before:left-1/2 hover:before:-translate-x-1/2"
                type={ButtonType.LIGHT}
                onClick={() => {
                }}
              >
                <FiDollarSign className="m-auto"/>
              </Button>
              <Button
                className="flex py-2 px-3 ml-auto hover:after:text-xs hover:after:-top-5 hover:after:left-1/2 hover:after:transform hover:after:-translate-x-1/2 hover:after:content-['Trash'] hover:after:absolute hover:after:bg-[#932d2e] hover:after:shadow-2xl hover:after:section hover:after:px-1 hover:after:rounded-sm hover:before:p-1 hover:before:absolute hover:before:bg-[#932d2e] hover:before:-top-[11px] hover:before:rotate-45 hover:before:left-1/2 hover:before:-translate-x-1/2"
                type={ButtonType.DANGER} onClick={() => {
              }}>
                <FiTrash className="m-auto"/>
              </Button>
            </div>
          </div>
        ))}
      </Card>
    </div>
  </div>;
}

export default withAuth(InventoryPage);