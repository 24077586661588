import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import { AuthProvider } from 'oidc-react';
import LoginCallbackPage from "./pages/LoginCallbackPage";
import IndexPage from "./pages/IndexPage";
import Dashboard from "./Dashboard";
import SkillTreePage from "./pages/SkillTreePage";
import HomePage from "./pages/HomePage";
import CreateProfile from "./pages/CreateProfilePage";
import CrimesPage from "./pages/CrimesPage";
import GymPage from "./pages/GymPage";
import AccountPage from "./pages/AccountPage";
import HospitalPage from "./pages/HospitalPage";
import AdminPage from "./pages/AdminPage";
import BattlePage from "./pages/BattlePage";
import TokensPage from "./pages/TokensPage";
import JailPage from "./pages/JailPage";
import InventoryPage from "./pages/InventoryPage";
import { store } from './state';
import { Provider } from 'react-redux';

const router = createBrowserRouter([
  {
    path: "/",
    element: <IndexPage />,
  },
    {
        path: "/login/callback",
        element: <LoginCallbackPage />,
    },
    {
        path: '/game',
        element: <Dashboard />,
        children: [
          {
            path: 'create-profile',
            element: <CreateProfile />,
          },
          {
            path: 'home',
            element: <HomePage />
          },
          {
            path: 'skill-tree',
            element: <SkillTreePage />
          },
          {
            path: 'crimes',
            element: <CrimesPage />
          },
          {
            path: 'gym',
            element: <GymPage />
          },
          {
            path: 'account',
            element: <AccountPage />
          },
          {
            path: 'hospital',
            element: <HospitalPage />
          },
          {
            path: 'jail',
            element: <JailPage />
          },
          {
            path: 'battle',
            element: <BattlePage />
          },
          {
            path: 'tidal-tokens',
            element: <TokensPage />
          },
          {
            path: 'inventory',
            element: <InventoryPage />
          },
          {
            path: 'admin',
            element: <AdminPage />
          }
        ]
    }
]);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
    <AuthProvider
      authority={process.env.REACT_APP_AUTH_URI}
      clientId="tidal"
      redirectUri={process.env.REACT_APP_AUTH_REDIRECT_URI}
      onSignIn={user => {
        window.location.href="/game/home"
      }}
      autoSignIn={true}
    >
      <Provider store={store}>
        <RouterProvider router={router} />
      </Provider>
    </AuthProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
