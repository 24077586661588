import {SignalRContext} from "./SignalRContext";
import {useContext, useState} from "react";
import {Outlet, useNavigate} from "react-router-dom";
import Layout from "./components/Layout";
import {HttpTransportType} from "@microsoft/signalr";
import {useAuth, withAuth} from "oidc-react";
import ChatOverlay from "./components/ChatOverlay";
import {useAppDispatch} from "./state/hooks";
import {setUser, updateUserVitals, updateWalletTidalTokens, updateWalletUsd} from "./state/user";
import {messageReceived} from "./state/communication";

function Dashboard(props: any) {
  const dispatch = useAppDispatch();
  const auth = useAuth();
  const signalR = useContext(SignalRContext);
  const [connectionOpen, setConnectionOpen] = useState(false);
  const [userCreated, setUserCreated] = useState(false);
  const navigate = useNavigate();

  const contextError = async (e: any) => {
    console.log('error');
    console.log(e);
  };

  const contextOpened = async () => {
    const currentUser = (await signalR.invoke('GetCurrentUser'));
    if (currentUser) {
      setUserCreated(true);
      dispatch(setUser({ user: currentUser }));
    } else {
      setUserCreated(false);
      navigate('/game/create-profile');
    }
    setConnectionOpen(true);
  };

  signalR.useSignalREffect('WalletsUpdated', (data: { usd?: number, tidalTokens?: number }) => {
    if (data.usd !== null && data.usd !== undefined) {
      dispatch(updateWalletUsd(data.usd));
    }
    if (data.tidalTokens !== null && data.tidalTokens !== undefined) {
      dispatch(updateWalletTidalTokens(data.tidalTokens));
    }
  }, []);

  signalR.useSignalREffect('UserExperienceUpdated', (data: { level?: number }) => {
    // if (data.level && user) {
    //   user.experience.level = data.level;
    //   updateUserState({user: user});
    // }
  }, []);

  signalR.useSignalREffect('UserVitalsUpdated', (data: { courage?: number, stamina?: number }) => {
    dispatch(updateUserVitals(data));
  }, []);
  
  signalR.useSignalREffect('MessageReceived', (data: any) => {
    dispatch(messageReceived(data));
  }, []);

  return (
    <div>
      <signalR.Provider
        connectEnabled={!!auth.userData?.access_token}
        dependencies={[auth]}
        url={process.env.REACT_APP_GAMEPLAY_HUB_URI as string}
        accessTokenFactory={() => auth.userData?.access_token!}
        onOpen={() => contextOpened()}
        onError={contextError}
        skipNegotiation={true}
        transport={HttpTransportType.WebSockets}
      >
        {connectionOpen &&
            <div>
              {userCreated && <Layout>
                <Outlet/>
                <ChatOverlay />
              </Layout>}
              {!userCreated && <Outlet/>}
            </div>
        }
      </signalR.Provider>
    </div>
  )
}

export default withAuth(Dashboard)