import {CityLocation} from "./CityLocation";
export enum StorageType
{
 Backpack = 0,
 Property = 1
}
export interface UserStorageSummary {
 id: string;
 location: CityLocation;
 storageType: StorageType;
 maxStorage: number;
 currentStorage: number;
}