import Card, {CardPadding, CardStyle} from "../components/Card";
import React, {useContext, useMemo, useState} from "react";
import Button, {ButtonType} from "../components/Button";
import VitalBar from "../components/VitalBar";
import SlideOver from "../components/SlideOver";
import {useAppSelector} from "../state/hooks";
import {selectUser} from "../state/user";

const BattlePage = () => {
  const user = useAppSelector(selectUser);
  const [showSlideOut, setShowSlideOut] = useState<boolean>(false);
  const [selectedUser, setSelectedUser] = useState();
  const [age, setAge] = useState<number>()

  useMemo(() => {
    const createdDate = new Date(user!.created);
    const today = new Date()
    const diffTime = Math.abs(today.valueOf() - createdDate.valueOf());
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    setAge(diffDays);
  }, [user]);

  const onClickShowUserDetails = (user:any) => {
    setSelectedUser(user);
    setShowSlideOut(!showSlideOut);
  }

  const onClickCloseSlideOut = () => {
    setSelectedUser(undefined);
    setShowSlideOut(false);
  }
  return (
    <div className="flex flex-col xl:grid xl:grid-cols-6 gap-3">
      <Card className="flex flex-row xl:flex-col w-full gap-3 xl:col-span-1 xl:self-start">
        <span className="my-auto">Alies</span>
        <Button className="flex gap-2 h-12 items-center xl:w-full" type={ButtonType.DARK} onClick={() => onClickShowUserDetails(user)}>
          <img className="h-8 w-8 border border-[#ecb274] object-cover rounded my-auto"
               src="https://imgur.com/7bLroYs.png"
               alt="profile"/>
          <span className="my-auto">{user?.name}</span>
        </Button>
      </Card>
      <div className="flex flex-col gap-3 col-span-5">
        <Card className="grid grid-cols-3 gap-3">
          <div className="flex gap-3 items-center text-[#cfcdbf] font-light mr-auto">
            <img className="h-8 w-8 border border-[#ecb274] object-cover rounded"
                 src="https://imgur.com/7bLroYs.png"
                 alt="profile"/>
            <span>{user?.name}</span>
          </div>
          <span className="text-[#cfcdbf] font-light m-auto">Vs</span>
          <div className="flex gap-3 items-center text-[#cfcdbf] font-light ml-auto">
            <span>Tidal Npc</span>
            <img className="h-8 w-8 border border-[#ecb274] object-cover rounded"
                 src="https://imgur.com/7bLroYs.png"
                 alt="profile"/>
          </div>
        </Card>
        <div className="grid grid-cols-2 gap-3">
          <Card className="flex flex-col" type={CardStyle.DEFAULT} padding={CardPadding.XSMALL}>
            <VitalBar title="Health" minValue={user?.vitals?.health || 0} maxValue={user?.maxVitals?.health || 0}
                      color="#932d2e"/>
            <img src="https://imgur.com/m6VkwJD.png" alt="user"/>
            <div className="flex gap-1 pt-1">
              <div className="grid grid-cols-2 xl:grid-cols-5 w-full gap-3">
                <Button className="flex gap-2 h-24 justify-center items-center xl:w-full" type={ButtonType.DARK} onClick={() => {}}>
                  <span className="my-auto">Melee</span>
                </Button>
                <Button className="flex gap-2 h-24 justify-center items-center xl:w-full" type={ButtonType.DARK} onClick={() => {}}>
                  <span className="my-auto">Primary</span>
                </Button>
                <Button className="flex gap-2 h-24 justify-center items-center xl:w-full" type={ButtonType.DARK} onClick={() => {}}>
                  <span className="my-auto">Secondary</span>
                </Button>
                <Button className="flex gap-2 h-24 justify-center items-center xl:w-full" type={ButtonType.DARK} onClick={() => {}}>
                  <span className="my-auto">Temporary</span>
                </Button>
                <Button className="flex gap-2 h-24 justify-center items-center xl:w-full" type={ButtonType.DARK} onClick={() => {}}>
                  <span className="my-auto">Consumable</span>
                </Button>
              </div>
            </div>
          </Card>
          <Card type={CardStyle.DEFAULT} padding={CardPadding.XSMALL}>
            <VitalBar title="Health" minValue={user?.vitals?.health || 0} maxValue={user?.maxVitals?.health || 0}
                      color="#932d2e"/>
            <img src="https://imgur.com/ELbwRqG.png" alt="user"/>
            <div className="flex gap-1 pt-1 pointer-events-none opacity-40">
              <div className="grid grid-cols-2 xl:grid-cols-5 w-full gap-3">
                <Button className="flex gap-2 h-24 justify-center items-center xl:w-full" type={ButtonType.DARK} onClick={() => {}}>
                  <span className="my-auto">Melee</span>
                </Button>
                <Button className="flex gap-2 h-24 justify-center items-center xl:w-full" type={ButtonType.DARK} onClick={() => {}}>
                  <span className="my-auto">Primary</span>
                </Button>
                <Button className="flex gap-2 h-24 justify-center items-center xl:w-full" type={ButtonType.DARK} onClick={() => {}}>
                  <span className="my-auto">Secondary</span>
                </Button>
                <Button className="flex gap-2 h-24 justify-center items-center xl:w-full" type={ButtonType.DARK} onClick={() => {}}>
                  <span className="my-auto">Temporary</span>
                </Button>
                <Button className="flex gap-2 h-24 justify-center items-center xl:w-full" type={ButtonType.DARK} onClick={() => {}}>
                  <span className="my-auto">Consumable</span>
                </Button>
              </div>
            </div>
          </Card>
        </div>
      </div>
      {showSlideOut && selectedUser &&
          <SlideOver onClose={() => onClickCloseSlideOut()}>
              {/*<Card type={CardStyle.DEFAULT} padding={CardPadding.NONE}>*/}
              {/*    <ul className="flex section_list_row divide-x divide-[#171716]">*/}
              {/*        <li className="w-full flex justify-center">*/}
              {/*            Stats*/}
              {/*        </li>*/}
              {/*        <li className="w-full flex justify-center">*/}
              {/*            Report*/}
              {/*        </li>*/}
              {/*    </ul>*/}
              {/*</Card>*/}
              <Card type={CardStyle.ORANGE} padding={CardPadding.SMALL}>
                  <div className="flex flex-col gap-1">
                      <div className="w-full max-h-24 bg-white relative">
                          <img className="h-full w-full object-cover object-bottom border border-[#ecb274]"
                               src="https://imgur.com/Pv92uqm.png" alt="profile banner"/>
                      </div>
                      <div className="flex max-h-16 max-w-16 gap-1 items-center">
                          <img className="h-full  w-full border border-[#ecb274] object-cover"
                               src="https://imgur.com/7bLroYs.png"
                               alt="profile"/>
                          <div className="flex flex-col text-xs whitespace-nowrap px-1">
                              <span><strong>Name:&nbsp;</strong>JKE</span>
                              <span><strong>Level:&nbsp;</strong>1</span>
                              <span><strong>Last Action:&nbsp;</strong>Now</span>
                              <span><strong>State:&nbsp;</strong>In Hospital</span>
                          </div>
                      </div>
                  </div>
              </Card>
              <Card>
                  <VitalBar minValue={30} maxValue={100} color="#932d2e" title="Health"/>
              </Card>
              <div className="grid grid-cols-2 gap-3 text-sm text-[#cfcdbf]">
                  <Card className="flex justify-center gap-1" padding={CardPadding.SMALL}>
                      <span>LEVEL</span>
                      <span className="text-[#BE8250FF]">1</span>
                  </Card>
                  <Card className="flex justify-center gap-1" padding={CardPadding.SMALL}>
                      <span>AGE</span>
                      <span className="text-[#BE8250FF]">{age}</span>
                  </Card>
              </div>
              <Card type={CardStyle.DEFAULT} padding={CardPadding.NONE}>
                  <ul className="grid grid-cols-7 section_list_row divide-x divide-[#171716]">
                      {/*<li className="relative group w-full flex justify-center items-center overflow-hidden">*/}
                      {/*    <FiCrosshair*/}
                      {/*        className=" transform translate-y-0 group-hover:translate-y-full ease-in-out duration-300 h-4 w-4"/>*/}
                      {/*    <span*/}
                      {/*        className="absolute text-xs scale-0 group-hover:scale-100 ease-in-out duration-300 text-[#0C0C0CCC]">Attack</span>*/}
                      {/*</li>*/}
                      {/*<li className="relative group w-full flex justify-center items-center overflow-hidden">*/}
                      {/*    <FiMail*/}
                      {/*        className=" transform translate-y-0 group-hover:translate-y-full ease-in-out duration-300 h-4 w-4"/>*/}
                      {/*    <span*/}
                      {/*        className="absolute text-xs scale-0 group-hover:scale-100 ease-in-out duration-300 text-[#0C0C0CCC]">Mail</span>*/}
                      {/*</li>*/}
                      {/*<li className="relative group w-full flex justify-center items-center overflow-hidden">*/}
                      {/*    <FiMessageSquare*/}
                      {/*        className=" transform translate-y-0 group-hover:translate-y-full ease-in-out duration-300 h-4 w-4"/>*/}
                      {/*    <span*/}
                      {/*        className="absolute text-xs scale-0 group-hover:scale-100 ease-in-out duration-300 text-[#0C0C0CCC]">Chat</span>*/}
                      {/*</li>*/}
                      {/*<li className="relative group w-full flex justify-center items-center overflow-hidden">*/}
                      {/*    <FiDollarSign*/}
                      {/*        className=" transform translate-y-0 group-hover:translate-y-full ease-in-out duration-300 h-4 w-4"/>*/}
                      {/*    <span*/}
                      {/*        className="absolute text-xs scale-0 group-hover:scale-100 ease-in-out duration-300 text-[#0C0C0CCC]">Send</span>*/}
                      {/*</li>*/}
                      {/*<li className="relative group w-full flex justify-center items-center overflow-hidden">*/}
                      {/*    <IoIosSwap*/}
                      {/*        className=" transform translate-y-0 group-hover:translate-y-full ease-in-out duration-300 h-4 w-4"/>*/}
                      {/*    <span*/}
                      {/*        className="absolute text-xs scale-0 group-hover:scale-100 ease-in-out duration-300 text-[#0C0C0CCC]">Trade</span>*/}
                      {/*</li>*/}
                      {/*<li className="relative group w-full flex justify-center items-center overflow-hidden">*/}
                      {/*    <FiThumbsUp*/}
                      {/*        className=" transform translate-y-0 group-hover:translate-y-full ease-in-out duration-300 h-4 w-4"/>*/}
                      {/*    <span*/}
                      {/*        className="absolute text-xs scale-0 group-hover:scale-100 ease-in-out duration-300 text-[#0C0C0CCC]">Add</span>*/}
                      {/*</li>*/}
                      {/*<li className="relative group w-full flex justify-center items-center overflow-hidden">*/}
                      {/*    <FiThumbsDown*/}
                      {/*        className=" transform translate-y-0 group-hover:translate-y-full ease-in-out duration-300 h-4 w-4"/>*/}
                      {/*    <span*/}
                      {/*        className="absolute text-xs scale-0 group-hover:scale-100 ease-in-out duration-300 text-[#0C0C0CCC]">Block</span>*/}
                      {/*</li>*/}
                  </ul>
              </Card>
          </SlideOver>
      }
    </div>
  );
}

export default BattlePage;