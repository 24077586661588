export enum CrimeOutcome {
  NEGATIVE = -1,
  NEUTRAL = 0,
  POSITIVE = 1,
}

export enum CrimeRewardType {
  Cash = 0,
  TidalTokens = 1,
  GameItem = 2,
  CrimeXp = 3,
  LevelXp = 4,
  Arrested = 5,
  Hospitalized = 6,
}