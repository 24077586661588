import Card, {CardStyle} from "../components/Card";
import {useCallback, useContext, useEffect, useState} from "react";
import {SignalRContext} from "../SignalRContext";
import Button, {ButtonType} from "../components/Button";
import SlideOver, {SlideOverWidth} from "../components/SlideOver";
import {Broadcast} from "../types/Broadcast";

const AdminPage = () => {

  enum PageViews {
    HOME_VIEW ,
    ENSURE_DATA_VIEW,
    BROADCAST,
  }

  const [currentView, setCurrentView] = useState<PageViews>(PageViews.HOME_VIEW);
  const [showCreateNewSystemNotification, setShowCreateNewSystemNotification] = useState<boolean>(false);
  const [broadcastMessage, setBroadcastMessage] = useState('');
  const [broadcastLink, setBroadcastLink] = useState('');
  const [broadcastEnabled, setBroadcastEnabled] = useState('false');
  const [allBroadcasts, setAllBroadcasts] = useState<Broadcast[]>([]);
  const [userId, setUserId] = useState<string | undefined>(undefined);
  const [gameItemId, setGameItemId] = useState<string | undefined>(undefined);
  const [gameItemTemplateId, setGameItemTemplateId] = useState<string | undefined>(undefined);
  const [gameItemTemplateRarityId, setGameItemTemplateRarityId] = useState<number | undefined>(0);
  const signalR = useContext(SignalRContext);
  const ensureData = async () => {
    await signalR.invoke('AdminRunEnsureData');
  }

  const onClickCreateNewBroadcast = async () => {
    await signalR.invoke('AdminCreateBroadcast', broadcastMessage.trim(), broadcastLink.trim());
  }

  const onClickToggleBroadcast = async (broadcastId: string, enabled: boolean) => {
    await signalR.invoke('AdminToggleBroadcast', broadcastId, enabled);
    await loadAllBroadcasts();
  }
  
  const onClickGiveUserItem = async () => {
    if (gameItemId) {
      await signalR.invoke('GiveUserGameItem', userId, gameItemId, null, null);
    } else {
      await signalR.invoke('GiveUserGameItem', userId, null, gameItemTemplateId, gameItemTemplateRarityId);
    }
  }

  const loadAllBroadcasts = useCallback(async () => {
    setAllBroadcasts([]);
    const allBroadcasts = await signalR.invoke('AdminGetAllBroadcasts');
    setAllBroadcasts(allBroadcasts);
  }, [signalR]);

  useEffect(() => {
    (async function () {
      await loadAllBroadcasts();
    })();
  }, [loadAllBroadcasts]);

  return (
    <div className="flex flex-col gap-3">
      <Card className="flex">
        <span className="text-[#cfcdbf] font-light">Admin</span>
        <div className="flex ml-auto gap-1">
          <Button type={ButtonType.DEAFULT} onClick={() => setCurrentView(PageViews.HOME_VIEW)}>Home</Button>
          <Button type={ButtonType.DEAFULT} onClick={() => setCurrentView(PageViews.ENSURE_DATA_VIEW)}>Ensure Data</Button>
          <Button type={ButtonType.DEAFULT} onClick={() => setCurrentView(PageViews.BROADCAST)}>Broadcasts</Button>
        </div>
      </Card>
      {currentView === PageViews.HOME_VIEW &&
          <div>
              home
          </div>
      }
      {currentView === PageViews.ENSURE_DATA_VIEW &&
          <div className="flex flex-col gap-3">
              <Card className="flex justify-between">
                  <span>Run this to add data to the database.</span>
                  <Button type={ButtonType.DEAFULT} onClick={ensureData}>Run Ensure Data</Button>
              </Card>
              <Card className="flex justify-between">
                  <div className="flex flex-col">
                      <span>Give User Item</span>
                      <input value={userId} onChange={(e) => setUserId(e.target.value)} placeholder="userId"/>
                      <input value={gameItemId} onChange={(e) => setGameItemId(e.target.value)} placeholder="game item id"/>
                      <input value={gameItemTemplateId} onChange={(e) => setGameItemTemplateId(e.target.value)} placeholder="game item template id"/>
                      <select value={gameItemTemplateRarityId} onChange={(e) => setGameItemTemplateRarityId(parseInt(e.target.value))}>
                          <option value={0}>
                              poor
                          </option>
                          <option value={1}>
                              common
                          </option>
                          <option value={2}>
                              uncommon
                          </option>
                          <option value={3}>
                              rare
                          </option>
                          <option value={4}>
                              epic
                          </option>
                          <option value={5}>
                              legendary
                          </option>
                      </select>
                  </div>
                  <Button type={ButtonType.DEAFULT} onClick={() => onClickGiveUserItem()}>Give</Button>
              </Card>
          </div>
      }
      {currentView === PageViews.BROADCAST &&
          <Card className="flex flex-col gap-4">
              <div className="flex justify-between items-center">
                  <p className="text-sm">Broadcasts</p>
                  <Button className="text-sm" type={ButtonType.DEAFULT} onClick={() => setShowCreateNewSystemNotification(true)}>Add</Button>
              </div>
              <div className="flex flex-col gap-3">
                {
                  allBroadcasts && allBroadcasts.map((broadcast) => (
                    <Card className="flex justify-between items-center" type={CardStyle.DEFAULT}>
                      <div className="flex flex-col">
                        <span className="text-xs">Message</span>
                        <span>{broadcast.message}</span>
                      </div>
                      <label className="flex gap-3">
                        {broadcast.enabled ? 'Enabled' : 'Disabled'}
                        <input type="checkbox" checked={broadcast.enabled} onChange={event => onClickToggleBroadcast(broadcast.id, !broadcast.enabled)} className="accent-[#BE8250FF]"/>
                      </label>
                    </Card>
                  ))
                }
              </div>
          </Card>
      }
      {showCreateNewSystemNotification &&
        <SlideOver width={SlideOverWidth.XLARGE} onClose={() => setShowCreateNewSystemNotification(false)}>
            <Card>
                <span>Broadcast Configuration.</span>
            </Card>
            <Card className="flex items-center justify-between">
                <span>Enable {broadcastEnabled} </span>
                <input value={broadcastEnabled} onChange={event => setBroadcastEnabled((!(broadcastEnabled === 'true')).toString())} type="checkbox" className="accent-[#BE8250FF]"/>
            </Card>
            <Card className="flex flex-col gap-3">
                <span>Message</span>
                <textarea value={broadcastMessage} onChange={event => setBroadcastMessage(event.target.value) } className="rounded bg-transparent border border-[#171716] p-2 outline-none min-h-96 max-h-96"></textarea>
            </Card>
            <Card className="flex flex-col gap-3">
                <span>Link</span>
                <input value={broadcastLink} onChange={event => setBroadcastLink(event.target.value)} type="text" className="rounded bg-transparent border border-[#171716] p-2 outline-none"></input>
            </Card>
            <div className="flex justify-between">
                <Button type={ButtonType.DARK} onClick={() => setShowCreateNewSystemNotification(false)}>Cancel</Button>
                <Button type={ButtonType.DEAFULT} onClick={() => onClickCreateNewBroadcast()}>Save</Button>
            </div>
        </SlideOver>
      }
    </div>
  );
}

export default AdminPage;